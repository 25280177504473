import { StylesContext } from "@mui/styles";
import React, {useRef, useState} from "react";

// IMPORT COMPONENTS
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// IMPORT CUSTOM
import Info from "../Info/Info.js";

// IMPORT UTILS
import { format } from "../../utils/format.js";

// IMPORT STYLE
import "./Line.css";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ReactToPrint from "react-to-print";
import {ComponentToPrintAI} from "../Print/ComponentToPrintAI";

const Line = (props) => {
  // PROPS
  const { content, result, language, values, refs } = props;
  const { label_DE, label_EN, label_FR, grade1, grade4, grade9 } = content;
  let componentRef7 = refs[0];
  let componentRef8 = refs[1];
  let componentRef9 = refs[2];
  // STATE
  const [open, setOpen] = useState(false);

  // CALCULATION
  const containerClass = open ? "line_subContainerOpen" : "line_subContainerClosed";
  const iconClass = open ? "line_iconOpen" : "line_iconClosed";
  let lineClass = grade1.design === "total" ? "line_total" : null;
  lineClass = grade1.design === "fee" ? "line_fee" : null;

  return (
    <>
      <TableRow className={"line_container " + lineClass} key={"main"}>
        {/*Add References for print buttons*/}
        <div style={{ display: "none" }}>
        <ComponentToPrintAI content={result.grade1} values={values} grade="Grade 1" ref={(el) => (componentRef7 = el)} language={language} />,
        <ComponentToPrintAI content={result.grade4} values={values} grade="Grade 4" ref={(el) => (componentRef8 = el)} language={language} />,
        <ComponentToPrintAI content={result.grade9} values={values} grade="Grade 9" ref={(el) => (componentRef9 = el)} language={language} />,
          </div>
        <TableCell>
          <div className="line_label">
            <p>{content["label_" + language]}</p>
            {grade1.details ? (
              <div className={"line_icon " + iconClass}>
                <AddCircleIcon onClick={() => setOpen(!open)} />
              </div>
            ) : null}
             </div>
        </TableCell>
        <TableCell>
          <p className="line_number">
          {/* Add print icon for grade 1 all in */}
          {(label_DE === "= All-In Lohnkosten" || label_EN === "= All-In rate" || label_FR === "= Coûts salariaux all-in") && (
              <ReactToPrint
                  trigger={() => (
                      <span className="print_icon"  style={{ display: 'inline', verticalAlign: 'middle',  marginRight: '1px' }}>
                        <LocalPrintshopIcon style={{ fontSize: '15px' }} />&nbsp;
                      </span>
                  )}
                  content={() => componentRef7}
              />
          )}
          {format(grade1.value)}</p>

        </TableCell>

        {result.grade4.isgav === "nongav" ? (
          <TableCell>
            <p className="line_number">
              {(label_DE === "= All-In Lohnkosten" || label_EN === "= All-In rate" || label_FR === "= Coûts salariaux all-in") && (
                <ReactToPrint
                    trigger={() => (
                        <span className="print_icon"  style={{ display: 'inline', verticalAlign: 'middle',  marginRight: '1px' }}>
                          <LocalPrintshopIcon style={{ fontSize: '15px' }} />&nbsp;
                        </span>
                    )}
                    content={() => componentRef8}
                />
            )}{format(grade4.value)}</p>
          </TableCell>
        ) : null}
        {result.grade9.isgav === "nongav" ? (
          <TableCell>
            <p className="line_number">
              {(label_DE === "= All-In Lohnkosten" || label_EN === "= All-In rate" || label_FR === "= Coûts salariaux all-in") && (
                  <ReactToPrint
                      trigger={() => (
                          <span className="print_icon"  style={{ display: 'inline', verticalAlign: 'middle',  marginRight: '1px' }}>
                            <LocalPrintshopIcon style={{ fontSize: '15px' }} />&nbsp;
                          </span>
                      )}
                      content={() => componentRef9}
                  />
              )}{format(grade9.value)}</p>
          </TableCell>
        ) : null}
      </TableRow>
      {grade1.details &&
        grade1.details.map((item, index) => {
          const valueGrade1 = format(grade1.details[index].value);
          const valueGrade4 = format(grade4.details[index].value);
          const valueGrade9 = format(grade9.details[index].value);
          const label = grade1.details[index]["label_" + language];
          const info = grade1.details[index]["info_" + language];
          if (valueGrade1 === "0.00" && valueGrade4 === "0.00" && valueGrade9 === "0.00") return null; // prettier-ignore
          return (
            <TableRow className={"line_subContainer " + containerClass} key={index}>
              <TableCell>
                <div className="line_subContainerLabel">
                  <p style={{ fontWeight: label === "Professional contribution GAV employee leasing (up to CHF 148'200 per year)" || label === "Berufsbeitrag GAV Personalverleih (bis CHF 148'200 pro Jahr)" || label === "Contribution professionnelle CCT Location de services (jusqu'à CHF 148'200 par an)"  ? "bold" : "normal" }}>{label}</p>
                  {info ? (
                    <div className="line_info">
                      <Info info={info} />
                    </div>
                  ) : null}
                </div>
              </TableCell>
              <TableCell>
                <p className="line_number">{valueGrade1}</p>{/*add print grade 1 all in*/}
              </TableCell>
              {result.grade4.isgav === "nongav" ? (
                <TableCell>
                  <p className="line_number">{valueGrade4}</p>{/*add print grade 1 all in*/}
                </TableCell>
              ) : null}
              {result.grade9.isgav === "nongav" ? (
                <TableCell>
                  <p className="line_number">{valueGrade9}</p>{/*add print grade 1 all in*/}
                </TableCell>
              ) : null}
            </TableRow>
          );
        })}
    </>
  );
};

export default Line;
