import React from "react";
import styles from "./ComponentToPrint.module.scss";
import {
  option_sex,
  option_age,
  option_target_pro,
  option_target_for,
  option_vacation,
} from "../../data/options.data.js";

import { labels, pdf } from "../../data/labels.data.js";

function format(item) {
  const currencyFractionDigits = new Intl.NumberFormat("de-CH", {
    style: "currency",
    currency: "CHF",
  }).resolvedOptions().maximumFractionDigits;

  const result = item.toLocaleString("de-CH", {
    maximumFractionDigits: currencyFractionDigits,
    minimumFractionDigits: 2,
  });
  return result;
}

export class ComponentToPrintAN extends React.PureComponent {
  render() {
    const { content, values, grade, language } = this.props;
    if (!content) return null;

    // MULTIPLIER
    const multiplier = content.config.multiplier;
    const mp_year = multiplier;
    const mp_month = mp_year / 12;
    const mp_hour = mp_year / content.config.hours_pj;
    const mp_day = (mp_year / content.config.hours_pj) * (values.hrw / 5);

    // BASISWERTE
    const geschlecht = option_sex[language].find(
      (i) => i.value === values.sex
    ).label;
    const alter = option_age[language].find(
      (i) => i.value === values.age
    ).label;
    const tgp = option_target_pro[language].find(
      (i) => i.value === values.tgp
    ).label;
    const tgf = option_target_for[language].find(
      (i) => i.value === values.tgf
    ).label;
    const vac = option_vacation[language].find(
      (i) => i.value === values.vac
    ).label;

    const fullPdfStructure = [
      /*  {
        /*title: pdf[language].kundenrate,
          type: "rowbold",
          value: content.kundenrate,
        },
        {
          title: pdf[language].gebuehren,
          type: "simple_row",
          value: -content.gebuehren,
        },
        {
          title: pdf[language].msp,
          type: "subrow",
          value: -content.msp,
        },
        {
          title: pdf[language].payrolling_fee,
          type: "subrow",
          value: -content.prf,
        },
        {
          title: pdf[language].allin,
          type: "rowbold",
          value: content.allin,
        },
        {
          title: pdf[language].ag_total,
          type: "simple_row",
          value: -content.ag_total,
        },
        {
          title: pdf[language].bgv_or,
          type: "subrow",
          value: -content.ag.bgv_or,
        },
        {
          title: pdf[language].bgv_os,
          type: "subrow",
          value: -content.ag.bgv_os,
        },
        {
          title: pdf[language].bgv_ueo,
          type: "subrow",
          value: -content.ag.bgv_ueo,
        },
        {
          title: pdf[language].ahv,
          type: "subrow",
          value: -content.ag.ahv,
        },
        {
          title: pdf[language].ahv_verwaltung,
          type: "subrow",
          value: -content.ag.ahv_verwaltung,
        },
        {
          title: pdf[language].fak,
          type: "subrow",
          value: -content.ag.fak,
        },
        {
          title: pdf[language].alv1,
          type: "subrow",
          value: -content.ag.alv1,
        },
        {
          title: pdf[language].alv2,
          type: "subrow",
          value: -content.ag.alv2,
        },
        {
          title: pdf[language].uvg_bu,
          type: "subrow",
          value: -content.ag.uvg_bu,
        },
        {
          title: pdf[language].uvg_zv,
          type: "subrow",
          value: -content.ag.uvg_zv,
        },
        {
          title: pdf[language].uvg_uel,
          type: "subrow",
          value: -content.ag.uvg_uel,
        },
        {
          title: pdf[language].ktg_regulaer,
          type: "subrow",
          value: -content.ag.ktg_regulaer,
        },
        {
          title: pdf[language].kant_bbb,
          type: "subrow",
          value: -content.ag.kant_bbb,
        },
        {
          title: pdf[language].bbb,
          type: "subrow",
          value: -content.ag.bbb,
        },
        {
          title: pdf[language].bruttolohn,
          type: "rowbold",
          value: content.bruttolohn,
        },
        {
          type: "empty",
        },
      {
        title: grade + " - " + labels[language].arbeitnehmer,
        type: "grid_header",
      },*/
      {
        title: pdf[language].basissatz,
        type: "rowbold",
        value: content.basissatz,
      },
      {
        title: pdf[language].rs_absenzen,
        type: "simple_row",
        value: content.rs_absenzen,
      },
      {
        title: pdf[language].bezahlte_feiertage,
        type: "subrow",
        value: content.bezahlte_feiertage,
      },
      {
        title: pdf[language].ferienrueckstellung,
        type: "subrow",
        value: content.ferienrueckstellung,
      },
      {
        title: pdf[language].dreizehnter,
        type: "subrow",
        value: content.dreizehnter,
      },
      {
        title: pdf[language].sonstige_absenzen,
        type: "subrow",
        value: content.sonstige_absenzen,
      },
      {
        title: pdf[language].bruttolohn,
        type: "rowbold",
        value: content.bruttolohn,
      },
      {
        title: pdf[language].an_total,
        type: "simple_row",
        value: content.an_total,
      },
      {
        title: pdf[language].bgv_or,
        type: "subrow",
        value: -content.an.bgv_or,
      },
      {
        title: pdf[language].bgv_os,
        type: "subrow",
        value: -content.an.bgv_os,
      },
      {
        title: pdf[language].bgv_ueo,
        type: "subrow",
        value: -content.an.bgv_ueo,
      },

      {
        title: pdf[language].ahv,
        type: "subrow",
        value: -content.an.ahv,
      },
      {
        title: pdf[language].alv1,
        type: "subrow",
        value: -content.an.alv1,
      },
      {
        title: pdf[language].alv2,
        type: "subrow",
        value: -content.an.alv2,
      },
      {
        title: pdf[language].uvg_nbu,
        type: "subrow",
        value: -content.an.uvg_nbu,
      },
      {
        title: pdf[language].uvg_uel,
        type: "subrow",
        value: -content.an.uvg_uel,
      },
      {
        title: pdf[language].bbb,
        type: "subrowbold",
        value: -content.an.bbb,
      },
      {
        title: pdf[language].netto,
        type: "rowbold",
        value: content.netto,
      },
      {
        title: pdf[language].quell_abzug,
        type: "simple_row",
        value: content.quellensteuerabzug,
        condition: "taxAtSource",
      },
      {
        title: pdf[language].netto_quell,
        type: "rowbold",
        value: content.quellensteuerlohn,
        condition: "taxAtSource",
      },
      {
        type: "empty",
      },
      {
        title: pdf[language].verrechenbarestunden,
        type: "lastrow",
        value: content.config.hours_pj / multiplier,
      },
      {
        title: pdf[language].verrechenbaretage,
        type: "lastrow",
        value: content.config.hours_pj / (values.hrw / 5) / multiplier,
      },
      {
        title: pdf[language].vl_risiko,
        type: "lastrow",
        value: content.config.risiko / multiplier,
      },
      {
        title: pdf[language].vl_spar,
        type: "lastrow",
        value: content.config.sparen / multiplier,
      },
      {
        type: "empty",
      },
      {
        title:
          pdf[language].berechnung_1 +
          values.pen +
          "% " +
          pdf[language].berechnung_2 +
          content.config.sollstunden +
          pdf[language].berechnung_3 +
          (
            ((content.config.sollstunden * values.pen * 0.01) / 42) *
            100
          ).toFixed(2) +
          "%.",
        type: "text",
      },
      {
        title: pdf[language].ohnegewaehr,
        type: "text",
        fontSize: "12",
      },
      {
        title:
          pdf[language].berechnung_fuer +
          geschlecht +
          ", " +
          alter +
          ", " +
          vac +
          ", " +
          tgf +
          " " +
          values.tgt +
          " CHF " +
          tgp +
          ".",
        type: "text",
      },
      {
        title: pdf[language].berechnung_fuer + pdf[language].quell_satz + content.quellensteuersatz + "%" ,
        type: "text",
        condition: "taxAtSource",
      },
      {
        title: pdf[language].urlaub,
        type: "text",
      },
    ];

    var pdfStructure = fullPdfStructure;
    if (!values.ckt) {
      pdfStructure = fullPdfStructure.filter(
        (item) => item.condition !== "taxAtSource"
      );
    }

    return (
      <div className={styles.payrollWrapper}>
        <table width="100%" className={styles.table}>
          <thead className={styles.print_head}>
            <tr>
              <th key="grade">
                {grade} - {labels[language].arbeitnehmer}
              </th>
              <th key="pro_stunde">{pdf[language].pro_stunde}</th>
              <th key="pro_tag">{pdf[language].pro_tag}</th>
              <th key="pro_monat">{pdf[language].pro_monat}</th>
              <th key="pro_jahr">{pdf[language].pro_jahr}</th>
            </tr>
          </thead>

          <tbody>
            <>
              {pdfStructure.map((item, index) => {
                if (item.value === 0) return null;
                if (item.type === "empty") {
                  return (
                    <tr className={styles[item.type]} key={index}>
                      <td key="grade"></td>
                      <td key="pro_stunde"></td>
                      <td key="pro_tag"></td>
                      <td key="pro_monat"></td>
                      <td key="pro_jahr"></td>
                    </tr>
                  );
                }

                if (item.type === "grid_header") {
                  return (
                    <tr className={styles[item.type]} key={index}>
                      <td key="grade">{item.title}</td>
                      <td key="pro_stunde">{pdf[language].pro_stunde}</td>
                      <td key="pro_tag">{pdf[language].pro_tag}</td>
                      <td key="pro_monat">{pdf[language].pro_monat}</td>
                      <td key="pro_jahr">{pdf[language].pro_jahr}</td>
                    </tr>
                  );
                }
                if (item.type === "text") {
                  return (
                    <tr className={styles[item.type]} key={index}>
                      <td colSpan="5">{item.title}</td>
                    </tr>
                  );
                }
                return (
                  <tr className={styles[item.type]} key={index}>
                    <td key="grade">{item.title}</td>
                    <td key="pro_stunde">{format(item.value * mp_hour)}</td>
                    <td key="pro_tag">{format(item.value * mp_day)}</td>
                    <td key="pro_monat">{format(item.value * mp_month)}</td>
                    <td key="pro_jahr">{format(item.value * mp_year)}</td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    );
  }
}
