// IMPORT BASICS
// import React, { useRef } from "react";

// IMPORT COMPONENTS
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info"; // Import InfoIcon from Material-UI

const TableToPrintHeader = (props) => {
    const { label, result } = props;
    return (
        <TableRow>
            <TableCell>
                <h2 style={{ margin: "0px" }}>{label}</h2>
            </TableCell>
            <TableCell>
                <div className="home_headerNumber" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", flex: 1 }}>
                    <IconButton
                        href="https://www.rmgroup.ch/en/payrolling/insurance-options/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <InfoIcon style={{ color: "#003b66", fontSize: "20px" }} />
                    </IconButton>
                    <p className="home_headerNumber" style={{ margin: "0", whiteSpace: "nowrap" }}>
                        Grade 1
                    </p>
                </div>
                <p className="home_subheaderNumber">Basic</p>
            </TableCell>
            {result.grade4.isgav === "nongav"? (
                <TableCell>
                    <div className="home_headerNumber" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", flex: 1 }}>
                        <IconButton
                            href="https://www.rmgroup.ch/en/payrolling/insurance-options/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <InfoIcon style={{ color: "#003b66", fontSize: "20px" }} />
                        </IconButton>
                        <p className="home_headerNumber" style={{ margin: "0", whiteSpace: "nowrap" }}>
                            Grade 4
                        </p>
                    </div>
                    <p className="home_subheaderNumber">Pension</p>
                </TableCell>
            ) : null}
            {result.grade9.isgav === "nongav"? (
                <TableCell>
                    <div className="home_headerNumber" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", flex: 1 }}>
                        <IconButton
                            href="https://www.rmgroup.ch/en/payrolling/insurance-options/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <InfoIcon style={{ color: "#003b66", fontSize: "20px" }} />
                        </IconButton>
                        <p className="home_headerNumber" style={{ margin: "0", whiteSpace: "nowrap" }}>
                            Grade 9
                        </p>
                    </div>
                    <p className="home_subheaderNumber">Max</p>
                </TableCell>
            ) : null}
        </TableRow>
    );
};

export default TableToPrintHeader;
