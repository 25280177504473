export const getStructure = (result, values) => {
  // this "result" is not the object result from the Home
  const structure = {
    ag: [],
    an: [],
  };

  let item;

  // **************************************
  // * ARBEITSGEBER
  // **************************************

  // *** BILL RATE ***
  item = {
    label_DE: "Kundenrate",
    label_EN: "Bill rate",
    label_FR: "Taux de clients",
  };
  for (let key in result) {
    item[key] = {
      value:
        result[key].bruttolohn +
        result[key].ag_total +
        result[key].msp +
        result[key].prf +
        result[key].pro,
    };
  }
  structure.ag.push(item);

  // *** AG GEBÜHREN ***
  item = {
    label_DE: "- Gebühren",
    label_EN: "- Fees",
    label_FR: "- Frais",
  };
  for (let key in result) {
    item[key] = {
      value: (result[key].msp + result[key].prf) * -1,
      details: [
        {
          label_DE: "MSP Marge",
          label_EN: "MSP Fee",
          label_FR: "Frais MSP",
          value: result[key].msp * -1,
        },
        {
          label_DE: "Payroll Fee",
          label_EN: "Payroll Fee",
          label_FR: "Frais de roulement",
          value: result[key].prf * -1,
        },
        {
          label_DE: "Debitorenrisiko",
          label_EN: "credit risk",
          label_FR: "Risque de débiteur",
          value: 0,
        },
      ],
    };
  }
  structure.ag.push(item);

  // *** ALL IN RATE ***
  item = {
    label_DE: "= All-In Lohnkosten",
    label_EN: "= All-In rate",
    label_FR: "Coûts salariaux all-in",
  };
  for (let key in result) {
    item[key] = {
      value: result[key].bruttolohn + result[key].ag_total + result[key].pro,
    };
  }
  structure.ag.push(item);

  // *** ZUSAETZLICHE RUECKSTELLUNGEN
  if (values.buffer_checked) {
    item = {
      label_DE: "- Zusätzliche Rückstellungen",
      label_EN: "- Additional provisions",
      label_FR: "- Provisions supplémentaires",
    };

    for (let key in result) {
      item[key] = {
        value: result[key].prov_total * -1,
        details: [
          {
            label_DE: values.provision_1_name,
            // label_EN: "MSP Fee",

            value: result[key].pro.provision_1 * -1,
          },
          {
            label_DE: values.provision_2_name,
            // label_EN: "Payroll Fee",

            value: result[key].pro.provision_2 * -1,
          },
          {
            label_DE: values.provision_3_name,
            // label_EN: "Payroll Fee",
            value: result[key].pro.provision_3 * -1,
          },
          {
            label_DE: values.provision_4_name,
            // label_EN: "Payroll Fee",
            value: result[key].pro.provision_4 * -1,
          },
          {
            label_DE: values.provision_5_name,
            // label_EN: "Payroll Fee",
            value: result[key].pro.provision_5 * -1,
          },
        ],
      };
    }
    structure.ag.push(item);
  } else {
  }

  // *** AG BEITRÄGE ARBEITGEBER***
  item = {
    label_DE: "- Beiträge Arbeitgeber",
    label_EN: "- Contributions employer",
    label_FR: "- Contributions de l'employeur",
  };
  for (let key in result) {
    item[key] = {
      value: result[key].ag_total * -1,
      details: [
        {
          label_DE: "BVG Risiko-Prämie & Admin-Gebühr",
          label_EN: "BVG risk premium & administrative cost",
          label_FR: "Prime de risque LPP & frais d'administrateur",
          value: result[key].ag.bgv_or * -1,
        },
        {
          label_DE: "BVG Sparbeitrag",
          label_EN: "BVG savings bonus",
          label_FR: "Cotisation d'épargne LPP ",
          value: result[key].ag.bgv_os * -1,
        },
        {
          label_DE: "BVG Über-Obligatorium",
          label_EN: "BVG supplementary",
          label_FR: "Surobligatoire LLP",
          value: result[key].ag.bgv_ueo * -1,
        },
        {
          label_DE: "AHV / IV / EO",
          label_EN: "AHV / IV / EO",
          label_FR: "AVS / AI / APG",
          value: result[key].ag.ahv * -1,
        },
        {
          label_DE: "AHV Verwaltungskosten",
          label_EN: "AHV administrative cost",
          label_FR: "Frais administratifs AVS",
          value: result[key].ag.ahv_verwaltung * -1,
        },
        {
          label_DE: "Familienausgleichskasse FAK",
          label_EN: "Family compensation fund FAK",
          label_FR: "Caisse d'allocations familiales CAF",
          value: result[key].ag.fak * -1,
        },
        {
          label_DE: "ALV Beitrag (bis CHF 148'200 pro Jahr)",
          label_EN: "ALV contribution (below CHF 148'200 per year)",
          label_FR: "Contribution AC (jusqu'à CHF 148'200 par an)",
          value: result[key].ag.alv1 * -1,
        },
        // {
        //   label_DE: "ALV II Beitrag (ab CHF 148'200 pro Jahr)",
        //   label_EN: "ALV II contribution (above CHF 148'200 per year)",
        //   label_FR: "Contribution AC II (à partir de CHF 148'200 par an)",
        //   value: result[key].ag.alv2 * -1,
        // },
        {
          label_DE: "UVG - Berufsunfall (bis CHF 148'200 pro Jahr)",
          label_EN: "UVG - occupational accident (below CHF 148'200 per year)",
          label_FR: "LAA - Accident du travail (jusqu'à CHF 148'200 par an)",
          value: result[key].ag.uvg_bu * -1,
        },
        {
          label_DE: "UVG - Zusatzversicherung (bis CHF 148'200 pro Jahr)",
          label_EN: "UVG - supplemental insurance (below CHF 148'200 per year)",
          label_FR:
            "LAA - Assurance complémentaire (jusqu'à CHF 148'200 par an)",
          value: result[key].ag.uvg_zv * -1,
        },
        {
          label_DE:
            "UVG - Überschusslohn (ab CHF 148'200 bis 315'000 pro Jahr)",
          label_EN:
            "UVG - excess wage (between CHF 148'200 and 315'000 per year)",
          label_FR:
            "LAA - Salaire excédentaire (entre CHF 148'200 et 315'000 par an)",
          value: result[key].ag.uvg_uel * -1,
        },
        {
          label_DE: "Krankenttaggeld (bis max. CHF 315'000 pro Jahr)",
          label_EN: "sick pay (up to a maximum of CHF 315'000 per year)",
          label_FR:
            "Indemnité journalière de maladie (jusqu'à 315 000 CHF par an au maximum)",
          value: result[key].ag.ktg_regulaer * -1,
          info_DE: "2 Tage Wartefrist",
          info_EN: "2 days waiting period",
          info_FR: "Délai d'attente de 2 jours",
        },
        {
          label_DE: "Kantonaler Berufsbildungsbeitrag",
          label_EN: "Cantonal vocational training contribution",
          label_FR: "Contribution cantonale à la formation professionnelle",
          value: result[key].ag.kant_bbb * -1,
        },
        {
          label_DE:
            "Berufsbeitrag GAV Personalverleih (bis CHF 148'200 pro Jahr)",
          label_EN:
            "Professional contribution GAV employee leasing (up to CHF 148'200 per year)",
          label_FR:
            "Contribution professionnelle CCT Location de services (jusqu'à CHF 148'200 par an)",
          value: result[key].ag.bbb * -1,
        },
      ],
    };
  }
  structure.ag.push(item);

  // *** AG BRUTTOLOHN ***
  item = {
    label_DE: "= Bruttolohn",
    label_EN: "= Gross salary",
    label_FR: "= Salaire brut",
  };
  for (let key in result) {
    item[key] = {
      value: result[key].bruttolohn,
      design: "total",
    };
  }
  structure.ag.push(item);

  // **************************************
  // * ARBEITNEHMER
  // **************************************

  // *** AG BASISSATZ ***
  item = {
    label_DE: "Basissatz",
    label_EN: "Base salary",
    label_FR: "Salaire de base",
  };
  for (let key in result) {
    item[key] = {
      value: result[key].basissatz,
    };
  }
  structure.an.push(item);

  // *** AG RÜCKSTELLUNG ABSENZEN ***
  item = {
    label_DE: "+ Aufschläge",
    label_EN: "+ Surcharges",
    label_FR: "Suppléments",
  };
  for (let key in result) {
    item[key] = {
      value: result[key].rs_absenzen,
      details: [
        {
          label_DE: "Feiertagsentschädigung",
          label_EN: "Public holiday compensation",
          label_FR: "Indemnité pour jours fériés",
          value: result[key].bezahlte_feiertage,
        },
        {
          label_DE: "Ferienentschädigung",
          label_EN: "Vacation compensation",
          label_FR: "Indemnité de vacances",
          value: result[key].ferienrueckstellung,
        },
        {
          label_DE: "13. Monatslohn",
          label_EN: "13. Paycheck",
          label_FR: "13e salaire",
          value: result[key].dreizehnter,
        },
        {
          label_DE: "Sonstige Absenzen & Aufschläge",
          label_EN: "Other absences & surcharges",
          label_FR: "Autres absences & majorations",
          value: result[key].sonstige_absenzen,
        },
      ],
    };
  }
  structure.an.push(item);

  // *** AN BRUTTOLOHN***
  item = {
    label_DE: "= Bruttolohn",
    label_EN: "= Gross salary",
    label_FR: "= Salaire brut",
  };
  for (let key in result) {
    item[key] = {
      value: result[key].bruttolohn,
      design: "subtotal",
    };
  }
  structure.an.push(item);

  // *** ANBEITRÄGE ARBEITNEHMER***
  item = {
    label_DE: "- Beiträge Arbeitnehmer",
    label_EN: "- Contributions employee",
    label_FR: "- Cotisations Salarié(e)",
  };
  for (let key in result) {
    item[key] = {
      value: -result[key].an_total,
      details: [
        {
          label_DE: "BVG Risiko-Prämie & Admin-Gebühr",
          label_EN: "BVG risk premium & administrative cost",
          label_FR: "Prime de risque LLP & frais d'administration",
          value: result[key].an.bgv_or * -1,
        },
        {
          label_DE: "BVG Sparbeitrag",
          label_EN: "BVG savings bonus",
          label_FR: "Cotisation d'épargne LLP",
          value: result[key].an.bgv_os * -1,
        },
        {
          label_DE: "BVG Über-Obligatorium",
          label_EN: "BVG supplementary",
          label_FR: "Surobligatoire LLP",
          value: result[key].an.bgv_ueo * -1,
        },
        {
          label_DE: "AHV / IV / EO",
          label_EN: "AHV / IV / EO",
          label_FR: "AVS / AI / APG",
          value: result[key].an.ahv * -1,
        },
        {
          label_DE: "ALV Beitrag (bis CHF 148'200 pro Jahr)",
          label_EN: "ALV contribution (below CHF 148'200 per year)",
          label_FR: "Contribution AC (jusqu'à CHF 148'200 par an)",
          value: result[key].an.alv1 * -1,
        },
        // {
        //   label_DE: "ALV II Beitrag (ab CHF 148'200 pro Jahr)",
        //   label_EN: "ALV II contribution (above CHF 148'200 per year)",
        //   label_FR: "Contribution AC II (à partir de CHF 148'200 par an)",
        //   value: result[key].an.alv2 * -1,
        // },
        {
          label_DE: "UVG - Nichtberufsunfall (bis CHF 148'200 pro Jahr)",
          label_EN:
            "UVG - non-occupational accident (below CHF 148'200 per year)",
          label_FR:
            "LAA - Accidents non professionnels (jusqu'à CHF 148'200 par an)",
          value: result[key].an.uvg_nbu * -1,
        },
        {
          label_DE:
            "UVG - Überschusslohn (ab CHF 148'200 bis 315'000 pro Jahr)",
          label_EN:
            "UVG - excess wages (between CHF 148'200 and 315'000 per year)",
          label_FR:
            "LAA - Salaire excédentaire (entre CHF 148'200 et 315'000 par an)",
          value: result[key].an.uvg_uel * -1,
        },
        {
          label_DE:
            "Berufsbeitrag GAV Personalverleih (bis CHF 148'200 pro Jahr)",
          label_EN:
            "Professional contribution GAV employee leasing (up to CHF 148'200 per year)",
          label_FR:
            "Contribution professionnelle CCT Location de services (jusqu'à CHF 148'200 par an)",
          value: result[key].an.bbb * -1,
        },
      ],
    };
  }
  structure.an.push(item);

  // *** AN NETTOLOHN***
  item = {
    label_DE: "= Nettolohn vor Steuern",
    label_EN: "= Net salary before tax",
    label_FR: "= Salaire net avant impôts",
  };
  for (let key in result) {
    item[key] = {
      value: result[key].netto,
      design: "total",
    };
  }
  structure.an.push(item);

  // LOHN NACH QUELLENSTEUER
  // *** AG GEBÜHREN ***
  if (result["grade1"].quellensteuerabzug != null && result["grade1"].quellensteuerabzug !== "") {
    item = {
      label_DE: "- Quellensteuerabzug",
      label_EN: "- Tax at source",
      label_FR: "- Prélèvement à la source",
    };
    for (let key in result) {
      item[key] = {
        value: result[key].quellensteuerabzug * -1,
      };
    }
    structure.an.push(item);

    item = {
      label_DE: "Quellensteuersatz in %",
      label_EN: "Tax rate at source in %",
      label_FR: "Taux d'impôt à la source en %",
    };
    for (let key in result) {
      item[key] = {
        value: result[key].quellensteuersatz,
      };
    }
    structure.an.push(item);

    item = {
      label_DE: "= Nettolohn nach Quellensteuer",
      label_EN: "= Net salary after tax at source",
      label_FR: "= salaire net après l'impôt à la source",
    };
    for (let key in result) {
      item[key] = {
        value: result[key].quellensteuerlohn,
        design: "total",
      };
    }
    structure.an.push(item);
  }

  // *** FEES ***
  item = {
    label_DE: "MSP Fee",
    label_EN: "MSP Fee",
    label_FR: "Frais MSP",
  };
  for (let key in result) {
    item[key] = {
      value: result[key].msp,
      design: "fee",
    };
  }
  structure.fee = [];
  structure.fee.push(item);

  return structure;
};
